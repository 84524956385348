.MuiSnackbar-root {
    align-self: center;
    left: auto;
    right: auto;

    .MuiPaper-root {
        color: #fff;
        padding: 0.75rem 1.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        
        > * {
            padding: 0;
        }

        &.MuiAlert-standardSuccess {
            background: $gruen;
        }
        &.MuiAlert-standardError {
            background: $rot;
        }
        .MuiAlert-icon {
            svg {
                color: #fff;
            }
        }
        .MuiAlert-message {
            font-family: museo_sns_w00_cond_500regular;
            font-size: 1rem;
        }
    }
}