@import "fonts";
@import "colors";
@import "snackbar";
@import "footer";

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
}

.MuiPaper-root {
  box-shadow: none;
  padding: 1.5rem;
}

// Images

.img-fluid {
  max-width: 100%;
}

// Links
a:link,
a:visited {
  text-decoration: none;
}
a:hover,
a:active {
  text-decoration: underline;
}

// Header
.MuiAppBar-root {
  background: #fff;
  padding: 1rem 0;

  .MuiContainer-root {
    display: flex;
    gap: 1rem;

    .navbar-brand {
      margin-right: auto;
    }

    .MuiButton-startIcon {
      svg {
        height: 1rem;
      }
    }
  }
}

// Fonts

body,
.MuiTypography-root,
.MuiButton-root {
  font-family: museo_slab_w00_300regular, Georgia, serif;
  text-transform: none;
}

strong {
  font-family: museo_slab_w00_500bold;
  font-weight: normal;
}

h1.MuiTypography-root,
h2.MuiTypography-root,
h3.MuiTypography-root,
h4.MuiTypography-root,
h5.MuiTypography-root,
h6.MuiTypography-root {
  font-family: museo_sns_w00_cond_500regular;
  font-weight: normal;
}
h2.MuiTypography-root {
  font-size: 1.6rem;
}
h3.MuiTypography-root {
  font-size: 1.375rem;
}
h4.MuiTypography-root {
  font-size: 1.1rem;
}

// Buttons

.btn-hellgrau {
  color: #000;
  background-color: $lightgray;
  border-color: white;
  &:hover {
    background: darken($lightgray, 3%);
  }
}
.btn-grau {
  color: #fff;
  background-color: $grau;
  border-color: white;
  &:hover {
    background: darken($grau, 3%);
  }
}

.WarnContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &.warning {
    background: $gelb;
    color: #444;

    .MuiButton-root {
      background: lighten($gelb, 20);

      &:hover {
        background: lighten($gelb, 15);
      }
    }
  }
  &.critical {
    background: $rot;
    color: #fff;

    .MuiButton-root {
      background: lighten($rot, 20);
      color: #fff;

      &:hover {
        background: lighten($rot, 15);
      }
    }
  }

  .WarnMessage {
    display: flex;
    align-items: center;
    gap: 1rem;

    .WarnLabel {
      font-weight: 700;
      font-family: museo_slab_w00_700bold;
    }
    .MuiTypography-root {
      margin-right: auto;
    }
  }
  .MuiButton-root {
    align-self: flex-end;
  }
}

// Main Content
.main {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1 0 auto;

  .MuiMasonry-root {
    width: auto;

    .MuiPaper-root {
      padding-bottom: 0.5rem;
    }
  }
}


// Details
.details {
  &.orange {
    .icon {
      background: $orange;
    }
    .border-top {
      border-top-color: $orange;
    }
  }
  &.grasgruen {
    .icon {
      background: $grasgruen;
    }
    .border-top {
      border-top-color: $grasgruen;
    }
  }
  &.hellblau {
    .icon {
      background: $hellblau;
    }
    .border-top {
      border-top-color: $hellblau;
    }
  }
  &.aubergine {
    .icon {
      background: $aubergine;
    }
    .border-top {
      border-top-color: $aubergine;
    }
  }

  h3.MuiTypography-root {
    margin-bottom: 1rem;
  }
  .DetailsHeader {
    display: flex;
    gap: 2rem;
    align-items: center;

    .icon {
      width: 5rem;
      height: 5rem;
      border-radius: 15px;

      svg {
        font-size: 2.75rem;
      }
    }
    h2.MuiTypography-root {
      margin-right: auto;
    }
  }
  p.MuiTypography-body1 {
    margin-bottom: 1rem;
  }
  ul li {
    line-height: 1.75;
  }
  .DetailsSidebar {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .list-unstyled {
      margin: 0;
    }
    .app-list {
      padding: 0 1.5rem;

      .AppLink {
        .icon {
          width: 2.5rem;
          height: 2.5rem;
          padding: 0.25rem;
          min-width: 0;

          svg {
            font-size: 1.5rem;
          }
        }
        .AppTitle {
          .lead {
            font-size: 1.1rem;
          }
        }
      }
    }
    h4.MuiTypography-root {
      margin-bottom: 1rem;
    }
    p.MuiTypography-body2:first-of-type {
      margin-bottom: 1rem;
    }
  }

  .grid {
    grid-template-columns: 9fr 3fr;
  }
}

// Admin
.admin {
  gap: 0;

  .AdminHeader {
    display: flex;
    gap: 2rem;
    align-items: center;
    margin-bottom: 2rem;
  }
  .MuiTabs-root {
    background: $petrol;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  }
  .MuiTabs-root + .MuiPaper-root {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .MuiTab-root {
    color: #fff;
    font-family: museo_sns_w00_cond_500regular;
    font-size: 1rem;
    transition: background-color 0.25s ease;

    &:hover {
      background: lighten($petrol, 5);
    }
  }
  .MuiTab-root.Mui-selected {
    color: $petrol;
    background: #fff;
  }
  .MuiTabs-indicator {
    display: none;
  }

  .tab-grid {
    display: grid;
    grid-template-columns: 3fr 9fr;
    gap: 1rem;
  }
  .tab-flex {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .ButtonAddWarning {
    max-width: 20rem;
  }
  .MuiPaper-root .MuiPaper-root {
    background: lighten($lightgray, 3);
    box-shadow: none;
    transition: background-color 0.25s ease;
    border-left: 7px solid transparent;
  }
  .WarningAdmin .MuiPaper-root {
    cursor: pointer;

    .severity {
      font-weight: 700;
      font-family: museo_slab_w00_700bold;
      min-width: 5rem;
    }
    &.info {
      border-left: 7px solid $hellblau;

      + .edit {
        border-left: 7px solid $hellblau;
      }

      .severity {
        color: $hellblau;
      }
    }
    &.warning {
      border-left: 7px solid $gelb;

      + .edit {
        border-left: 7px solid $gelb;
      }

      .severity {
        color: $gelb;
      }
    }
    &.critical {
      border-left: 7px solid $rot;

      + .edit {
        border-left: 7px solid $rot;
      }

      .severity {
        color: $rot;
      }
    }
    &.edit {
      background: lighten($lightgray, 4);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin-top: -3px;
      padding-top: 2rem;

      .WarningEdit {
        width: 100%;
      }

      &:hover {
        background: lighten($lightgray, 4);
        cursor: default;
      }
    }
  }
  .WarningAdmin .MuiPaper-root {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .WarningAdmin .MuiPaper-root:hover {
    background: $lightgray;
  }
}
.WarningEdit .row {
  display: flex;
  gap: 1rem;
  align-items: center;

  &.url {
    .MuiTextField-root {
      flex: 1;
    }
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  .MuiFormControlLabel-label {
    font-weight: 700;
    font-family: museo_slab_w00_700bold;
  }

  .RadioInfo span {
    color: $hellblau;
  }
  .RadioWarning span {
    color: $gelb;
  }
  .RadioCritical span {
    color: $rot;
  }

  .WarningTextFields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1 1 55%;
  }
  .WarningDateTime {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1 1 2%;
    min-width: 165px;
  }
  .MuiFormLabel-root.Mui-focused {
    color: $petrol;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $petrol;
  }
  .MuiInputBase-input,
  .MuiFormLabel-root {
    font-family: museo_slab_w00_300regular, Georgia, serif;
  }
  .WarningButtons {
    display: flex;
    gap: 1rem;
    margin-left: auto;

    .MuiButton-root {
      background: $petrol;

      &:hover {
        background: darken($petrol, 3);
      }

      &.ButtonDelete {
        background: $rot;

        &:hover {
          background: darken($rot, 3);
        }
      }

      &.secondary {
        background: $lightgray;
        color: #000;

        &:hover {
          background: darken($lightgray, 3);
        }
      }
    }
  }
}
.MuiDialog-root {
  .MuiDateTimePickerToolbar-timeDigitsContainer .MuiPickersToolbarText-root::after {
    content: ":00";
  }
  .MuiButtonBase-root.Mui-selected .MuiSvgIcon-root {
    color: $petrol;
  }
  .MuiPickersCalendarHeader-label,
  .MuiPickersDay-root,
  .MuiClockNumber-root {
    font-family: museo_slab_w00_300regular, Georgia, serif;
  }
  .MuiClock-pin,
  .MuiClockPointer-root,
  .MuiClockPointer-root .MuiClockPointer-thumb,
  .MuiTabs-indicator,
  .MuiPickersDay-root.Mui-selected {
    background: $petrol;
  }
  .MuiClockPointer-root .MuiClockPointer-thumb {
    border-color: $petrol;
  }
  .MuiDialogActions-root {
    .MuiButtonBase-root {
      background: $lightgray;
      color: #000;

      &:hover {
        background: darken($lightgray, 3);
      }
    }
    .MuiButtonBase-root:last-child {
      background: $petrol;
      color: #fff;

      &:hover {
        background: darken($petrol, 3);
      }
    }
  }
}

// Grid
.grid {
  display: grid;
  gap: 2rem;
  

  &.grid-2 {
    grid-template-columns: 1fr 1fr;
  }
  &.grid-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  &.subgrid {
    grid-template-rows: auto 1fr;
  }

  nav {
    margin-top: 1.5rem;
  }
}

.border-top {
  border-top: 7px solid $grau;
}

.border-top-orange {
  border-top: 7px solid $orange;
}

.border-top-petrol {
  border-top: 7px solid $petrol;
}

.border-top-grasgruen {
  border-top: 7px solid $grasgruen;
}

.border-top-hellblau {
  border-top: 7px solid $hellblau;
}

.border-top-aubergine {
  border-top: 7px solid $aubergine;
}

.border-top-rot {
  border-top: 7px solid $rot;
}
.border-top-gelb {
  border-top: 7px solid $gelb;
}
.border-top-gruen {
  border-top: 7px solid $gruen;
}
.border-top-violett {
  border-top: 7px solid $violett;
}

.news-header {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  .news-logo {
    width: 75px;
  }

  .news-title {
    background: $petrol;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }
}

// AppLinks

.app-list {
  h1,
  h2 {
    font-size: 1.375rem;
    text-transform: uppercase;
    letter-spacing: 0.075em;
  }
  .AppLink {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 1.25rem 0;
    transition: all 0.25s ease;
    border-radius: 10px;

    .AppTitle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.3rem;
      margin-right: auto;

      .AppTitleFriendly {
        display: flex;
        gap: 0.5rem;
      }

      .lead {
        font-family: museo_sns_w00_cond_500regular;
        font-size: 1.375rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
      .MuiChip-root {
        background: $gruen;
        color: #fff;
        font-family: museo_sns_w00_cond_500regular;

        svg {
          color: #fff;
          height: 0.75rem;
        }
      }
    }

    &.external {
      .AppTitle {
        svg {
          height: 0.8rem;
          color: $grau;
        }
      }
    }

    &:hover {
      text-decoration: none;
      background: #edf1f2;

      .AppDetailsLink svg {
        opacity: 0.7;
      }
    }

    .AppDetailsLink {
      padding-right: 1rem;

      svg {
        opacity: 0;
        font-size: 1.2rem;
        transition: opacity 0.25s ease;

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}

// Icons

.list-unstyled {
  list-style-type: none;
  padding: 0;
}

.icon {
  display: grid;
  place-items: center;
  color: #fff;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 10px;

  svg {
    font-size: 2rem;
  }

  &.info {
    background: $hellblau;
  }
  &.warning {
    background: lighten($gelb, 20);
    color: #444;
  }
  &.critical {
    background: lighten($rot, 20);
  }
  &.orange {
    background: $orange;
  }
  &.grasgruen {
    background: $grasgruen;
  }
  &.hellblau {
    background: $hellblau;
  }
  &.aubergine {
    background: $aubergine;
  }
  &.grau {
    background: $grau;
  }
  &.violett {
    background: $violett;
  }
}

.loadingSpinner {
  font-size: 2rem;
  --fa-primary-color: #007191;
  --fa-secondary-color: #f07b00;
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
  animation: loading 1s ease-in-out infinite;
}

@keyframes loading {
  from {
    transform: rotate(40deg);
  }
  to {
    transform: rotate(400deg);
  }
}

// Newsletter

.crArchive {
  border: none;
  padding-bottom: 1rem;

  @media (max-width: 767px) {
    max-height: 177px;
  }
  overflow-y: auto;

  div:nth-child(n+4) {
    display: none;
  }

  .crArchiveItem {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 0.5rem 2px;
    font-family: museo_sns_w00_cond_500regular;
    letter-spacing: 0.02em;
    font-size: 1.25rem;
    transition: all 0.25s ease;
    border-radius: 10px;
    @media (min-width: 992px) {
      font-size: 1.25rem;
    }

    .crArchiveItemDate {
      color: $petrol;
      font-family: museo_slab_w00_300regular;
      font-size: 1rem;
      min-width: 70px;
      padding-left: 0.2rem;
      transition: all 0.25s ease;
    }

    &:hover {
      text-decoration: none;
      background: #edf1f2;
    }

    .crArchiveItemImage,
    .crArchiveItemDescription {
      display: none;
    }
  }
}

.icon {
  min-width: 58px;
}

// Responsive

@media screen and (min-width: 1536px) {
  .MuiContainer-root {
    max-width: 1536px;
  }
}

@media screen and (max-width: 1536px) {
  .MuiContainer-root {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1100px) {
  .grid {
    &.grid-3 {
      grid-template-columns: 1fr 1fr;
    }
  }
  .details {
    .grid {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 768px) {
  header {
    .MuiButton-root {
      display: none;
    }
  }
  .grid {
    &.grid-2,
    &.grid-3 {
      grid-template-columns: 1fr;
    }
  }
}
@media screen and (max-width: 600px) {
  .MuiPaper-root {
    padding: 1rem;
  }
  header .MuiContainer-root {
    padding: 0;
  }
  .WarnMessage {
    flex-direction: column;
    text-align: center;
  }
}
@media (any-hover: none) {
  .app-list .AppLink .AppDetailsLink {
    padding-right: 0;

    svg {
      opacity: 0.4;
    }
  }
}