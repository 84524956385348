@font-face {
  font-family: museo_sns_w00_cond_700regular;
  src: url("../assets/webfonts/museo/museosnsw00-cond700.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: museo_slab_w00_300regular;
  src: url("../assets/webfonts/museo/museoslabw00-300.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: museo_slab_w00_500bold;
  src: url("../assets/webfonts/museo/museoslabw00-500.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: museo_slab_w00_700bold;
  src: url("../assets/webfonts/museo/museoslabw00-700.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: museo_sns_w00_cond_500regular;
  src: url("../assets/webfonts/museo/museosnsw00-cond500.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
