.footer {
    padding: 2rem 0 1rem 0;
    flex-shrink: 0;
    text-align: center;

    p.MuiTypography-root {
        font-size: 0.8rem;
        text-align: center;

        span:after {
            content: " – ";
        }
        span:last-child:after {
            content: "";
        }
        &.madewithlove {
            cursor: pointer;
            display: inline-block;
        }
    }

    svg {
        color: $petrol;
    }

    .footerHeart {
        color: $rot;
    }
}

@media screen and (max-width: 600px) {
    .footer {
        p.MuiTypography-root {
            span {
                display: block;

                &:after {
                    content: "";
                }

                &:nth-child(2) {
                    display: inline;

                    &:after {
                        content: " – ";
                    }
                }
                &:nth-child(3) {
                    display: inline;
                }
            }
        }  
    }
}