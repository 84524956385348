// colors

body {
    color: #000;
  }
  
  $orange: #f07b00;
  $petrol: #007191;
  $grasgruen: #01a38a;
  $hellblau: #22b9d6;
  $aubergine: #8d1d46;
  $rot: #e42112;
  $gelb: #ffca01;
  $hellelinien: #dbe3e4;
  $links: #555f65;
  $lightgray: #edf1f2;
  $grau: #b4b4b4;
  $gruen: #27ae60;
  $violett: #9b59b6;

  
  .bg-lightgray {
    background: $lightgray;
  }
  
  .bg-petrol {
    background: $petrol;
  }
  
  body {
    color: #000;
  }
  
  a {
    color: $links;
  }
  .details {
    h1, h2, h3, h4, h5, h6 {
      color: $links;
    }
  }
  
  .text-orange {
    color: $orange;
  }
  
  .text-rot {
    color: $rot;
  }
  .text-gelb {
    color: $gelb;
  }
  .text-gruen {
    color: $gruen;
  }
  .text-petrol {
    color: $petrol;
  }
  
  .text-grasgruen {
    color: $grasgruen;
  }
  
  .text-hellblau {
    color: $hellblau;
  }
  
  .text-aubergine {
    color: $aubergine;
  }
  .text-violett {
    color: $violett;
  }
  .text-links {
    color: $links;
  }