@charset "UTF-8";
@font-face {
  font-family: museo_sns_w00_cond_700regular;
  src: url("../assets/webfonts/museo/museosnsw00-cond700.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: museo_slab_w00_300regular;
  src: url("../assets/webfonts/museo/museoslabw00-300.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: museo_slab_w00_500bold;
  src: url("../assets/webfonts/museo/museoslabw00-500.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: museo_slab_w00_700bold;
  src: url("../assets/webfonts/museo/museoslabw00-700.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: museo_sns_w00_cond_500regular;
  src: url("../assets/webfonts/museo/museosnsw00-cond500.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
body {
  color: #000;
}

.bg-lightgray {
  background: #edf1f2;
}

.bg-petrol {
  background: #007191;
}

body {
  color: #000;
}

a {
  color: #555f65;
}

.details h1, .details h2, .details h3, .details h4, .details h5, .details h6 {
  color: #555f65;
}

.text-orange {
  color: #f07b00;
}

.text-rot {
  color: #e42112;
}

.text-gelb {
  color: #ffca01;
}

.text-gruen {
  color: #27ae60;
}

.text-petrol {
  color: #007191;
}

.text-grasgruen {
  color: #01a38a;
}

.text-hellblau {
  color: #22b9d6;
}

.text-aubergine {
  color: #8d1d46;
}

.text-violett {
  color: #9b59b6;
}

.text-links {
  color: #555f65;
}

.MuiSnackbar-root {
  align-self: center;
  left: auto;
  right: auto;
}
.MuiSnackbar-root .MuiPaper-root {
  color: #fff;
  padding: 0.75rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.MuiSnackbar-root .MuiPaper-root > * {
  padding: 0;
}
.MuiSnackbar-root .MuiPaper-root.MuiAlert-standardSuccess {
  background: #27ae60;
}
.MuiSnackbar-root .MuiPaper-root.MuiAlert-standardError {
  background: #e42112;
}
.MuiSnackbar-root .MuiPaper-root .MuiAlert-icon svg {
  color: #fff;
}
.MuiSnackbar-root .MuiPaper-root .MuiAlert-message {
  font-family: museo_sns_w00_cond_500regular;
  font-size: 1rem;
}

.footer {
  padding: 2rem 0 1rem 0;
  flex-shrink: 0;
  text-align: center;
}
.footer p.MuiTypography-root {
  font-size: 0.8rem;
  text-align: center;
}
.footer p.MuiTypography-root span:after {
  content: " – ";
}
.footer p.MuiTypography-root span:last-child:after {
  content: "";
}
.footer p.MuiTypography-root.madewithlove {
  cursor: pointer;
  display: inline-block;
}
.footer svg {
  color: #007191;
}
.footer .footerHeart {
  color: #e42112;
}

@media screen and (max-width: 600px) {
  .footer p.MuiTypography-root span {
    display: block;
  }
  .footer p.MuiTypography-root span:after {
    content: "";
  }
  .footer p.MuiTypography-root span:nth-child(2) {
    display: inline;
  }
  .footer p.MuiTypography-root span:nth-child(2):after {
    content: " – ";
  }
  .footer p.MuiTypography-root span:nth-child(3) {
    display: inline;
  }
}
html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
}

.MuiPaper-root {
  box-shadow: none;
  padding: 1.5rem;
}

.img-fluid {
  max-width: 100%;
}

a:link,
a:visited {
  text-decoration: none;
}

a:hover,
a:active {
  text-decoration: underline;
}

.MuiAppBar-root {
  background: #fff;
  padding: 1rem 0;
}
.MuiAppBar-root .MuiContainer-root {
  display: flex;
  gap: 1rem;
}
.MuiAppBar-root .MuiContainer-root .navbar-brand {
  margin-right: auto;
}
.MuiAppBar-root .MuiContainer-root .MuiButton-startIcon svg {
  height: 1rem;
}

body,
.MuiTypography-root,
.MuiButton-root {
  font-family: museo_slab_w00_300regular, Georgia, serif;
  text-transform: none;
}

strong {
  font-family: museo_slab_w00_500bold;
  font-weight: normal;
}

h1.MuiTypography-root,
h2.MuiTypography-root,
h3.MuiTypography-root,
h4.MuiTypography-root,
h5.MuiTypography-root,
h6.MuiTypography-root {
  font-family: museo_sns_w00_cond_500regular;
  font-weight: normal;
}

h2.MuiTypography-root {
  font-size: 1.6rem;
}

h3.MuiTypography-root {
  font-size: 1.375rem;
}

h4.MuiTypography-root {
  font-size: 1.1rem;
}

.btn-hellgrau {
  color: #000;
  background-color: #edf1f2;
  border-color: white;
}
.btn-hellgrau:hover {
  background: #e4eaec;
}

.btn-grau {
  color: #fff;
  background-color: #b4b4b4;
  border-color: white;
}
.btn-grau:hover {
  background: #acacac;
}

.WarnContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.WarnContainer.warning {
  background: #ffca01;
  color: #444;
}
.WarnContainer.warning .MuiButton-root {
  background: #ffdf67;
}
.WarnContainer.warning .MuiButton-root:hover {
  background: #ffda4e;
}
.WarnContainer.critical {
  background: #e42112;
  color: #fff;
}
.WarnContainer.critical .MuiButton-root {
  background: #f37369;
  color: #fff;
}
.WarnContainer.critical .MuiButton-root:hover {
  background: #f15d51;
}
.WarnContainer .WarnMessage {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.WarnContainer .WarnMessage .WarnLabel {
  font-weight: 700;
  font-family: museo_slab_w00_700bold;
}
.WarnContainer .WarnMessage .MuiTypography-root {
  margin-right: auto;
}
.WarnContainer .MuiButton-root {
  align-self: flex-end;
}

.main {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1 0 auto;
}
.main .MuiMasonry-root {
  width: auto;
}
.main .MuiMasonry-root .MuiPaper-root {
  padding-bottom: 0.5rem;
}

.details.orange .icon {
  background: #f07b00;
}
.details.orange .border-top {
  border-top-color: #f07b00;
}
.details.grasgruen .icon {
  background: #01a38a;
}
.details.grasgruen .border-top {
  border-top-color: #01a38a;
}
.details.hellblau .icon {
  background: #22b9d6;
}
.details.hellblau .border-top {
  border-top-color: #22b9d6;
}
.details.aubergine .icon {
  background: #8d1d46;
}
.details.aubergine .border-top {
  border-top-color: #8d1d46;
}
.details h3.MuiTypography-root {
  margin-bottom: 1rem;
}
.details .DetailsHeader {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.details .DetailsHeader .icon {
  width: 5rem;
  height: 5rem;
  border-radius: 15px;
}
.details .DetailsHeader .icon svg {
  font-size: 2.75rem;
}
.details .DetailsHeader h2.MuiTypography-root {
  margin-right: auto;
}
.details p.MuiTypography-body1 {
  margin-bottom: 1rem;
}
.details ul li {
  line-height: 1.75;
}
.details .DetailsSidebar {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.details .DetailsSidebar .list-unstyled {
  margin: 0;
}
.details .DetailsSidebar .app-list {
  padding: 0 1.5rem;
}
.details .DetailsSidebar .app-list .AppLink .icon {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.25rem;
  min-width: 0;
}
.details .DetailsSidebar .app-list .AppLink .icon svg {
  font-size: 1.5rem;
}
.details .DetailsSidebar .app-list .AppLink .AppTitle .lead {
  font-size: 1.1rem;
}
.details .DetailsSidebar h4.MuiTypography-root {
  margin-bottom: 1rem;
}
.details .DetailsSidebar p.MuiTypography-body2:first-of-type {
  margin-bottom: 1rem;
}
.details .grid {
  grid-template-columns: 9fr 3fr;
}

.admin {
  gap: 0;
}
.admin .AdminHeader {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-bottom: 2rem;
}
.admin .MuiTabs-root {
  background: #007191;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.admin .MuiTabs-root + .MuiPaper-root {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.admin .MuiTab-root {
  color: #fff;
  font-family: museo_sns_w00_cond_500regular;
  font-size: 1rem;
  transition: background-color 0.25s ease;
}
.admin .MuiTab-root:hover {
  background: #0085ab;
}
.admin .MuiTab-root.Mui-selected {
  color: #007191;
  background: #fff;
}
.admin .MuiTabs-indicator {
  display: none;
}
.admin .tab-grid {
  display: grid;
  grid-template-columns: 3fr 9fr;
  gap: 1rem;
}
.admin .tab-flex {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.admin .ButtonAddWarning {
  max-width: 20rem;
}
.admin .MuiPaper-root .MuiPaper-root {
  background: #f6f8f8;
  box-shadow: none;
  transition: background-color 0.25s ease;
  border-left: 7px solid transparent;
}
.admin .WarningAdmin .MuiPaper-root {
  cursor: pointer;
}
.admin .WarningAdmin .MuiPaper-root .severity {
  font-weight: 700;
  font-family: museo_slab_w00_700bold;
  min-width: 5rem;
}
.admin .WarningAdmin .MuiPaper-root.info {
  border-left: 7px solid #22b9d6;
}
.admin .WarningAdmin .MuiPaper-root.info + .edit {
  border-left: 7px solid #22b9d6;
}
.admin .WarningAdmin .MuiPaper-root.info .severity {
  color: #22b9d6;
}
.admin .WarningAdmin .MuiPaper-root.warning {
  border-left: 7px solid #ffca01;
}
.admin .WarningAdmin .MuiPaper-root.warning + .edit {
  border-left: 7px solid #ffca01;
}
.admin .WarningAdmin .MuiPaper-root.warning .severity {
  color: #ffca01;
}
.admin .WarningAdmin .MuiPaper-root.critical {
  border-left: 7px solid #e42112;
}
.admin .WarningAdmin .MuiPaper-root.critical + .edit {
  border-left: 7px solid #e42112;
}
.admin .WarningAdmin .MuiPaper-root.critical .severity {
  color: #e42112;
}
.admin .WarningAdmin .MuiPaper-root.edit {
  background: #f9fafb;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -3px;
  padding-top: 2rem;
}
.admin .WarningAdmin .MuiPaper-root.edit .WarningEdit {
  width: 100%;
}
.admin .WarningAdmin .MuiPaper-root.edit:hover {
  background: #f9fafb;
  cursor: default;
}
.admin .WarningAdmin .MuiPaper-root {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.admin .WarningAdmin .MuiPaper-root:hover {
  background: #edf1f2;
}

.WarningEdit .row {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.WarningEdit .row.url .MuiTextField-root {
  flex: 1;
}
.WarningEdit .row:not(:last-child) {
  margin-bottom: 2rem;
}
.WarningEdit .row .MuiFormControlLabel-label {
  font-weight: 700;
  font-family: museo_slab_w00_700bold;
}
.WarningEdit .row .RadioInfo span {
  color: #22b9d6;
}
.WarningEdit .row .RadioWarning span {
  color: #ffca01;
}
.WarningEdit .row .RadioCritical span {
  color: #e42112;
}
.WarningEdit .row .WarningTextFields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1 1 55%;
}
.WarningEdit .row .WarningDateTime {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1 1 2%;
  min-width: 165px;
}
.WarningEdit .row .MuiFormLabel-root.Mui-focused {
  color: #007191;
}
.WarningEdit .row .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #007191;
}
.WarningEdit .row .MuiInputBase-input,
.WarningEdit .row .MuiFormLabel-root {
  font-family: museo_slab_w00_300regular, Georgia, serif;
}
.WarningEdit .row .WarningButtons {
  display: flex;
  gap: 1rem;
  margin-left: auto;
}
.WarningEdit .row .WarningButtons .MuiButton-root {
  background: #007191;
}
.WarningEdit .row .WarningButtons .MuiButton-root:hover {
  background: #006582;
}
.WarningEdit .row .WarningButtons .MuiButton-root.ButtonDelete {
  background: #e42112;
}
.WarningEdit .row .WarningButtons .MuiButton-root.ButtonDelete:hover {
  background: #d61f11;
}
.WarningEdit .row .WarningButtons .MuiButton-root.secondary {
  background: #edf1f2;
  color: #000;
}
.WarningEdit .row .WarningButtons .MuiButton-root.secondary:hover {
  background: #e4eaec;
}

.MuiDialog-root .MuiDateTimePickerToolbar-timeDigitsContainer .MuiPickersToolbarText-root::after {
  content: ":00";
}
.MuiDialog-root .MuiButtonBase-root.Mui-selected .MuiSvgIcon-root {
  color: #007191;
}
.MuiDialog-root .MuiPickersCalendarHeader-label,
.MuiDialog-root .MuiPickersDay-root,
.MuiDialog-root .MuiClockNumber-root {
  font-family: museo_slab_w00_300regular, Georgia, serif;
}
.MuiDialog-root .MuiClock-pin,
.MuiDialog-root .MuiClockPointer-root,
.MuiDialog-root .MuiClockPointer-root .MuiClockPointer-thumb,
.MuiDialog-root .MuiTabs-indicator,
.MuiDialog-root .MuiPickersDay-root.Mui-selected {
  background: #007191;
}
.MuiDialog-root .MuiClockPointer-root .MuiClockPointer-thumb {
  border-color: #007191;
}
.MuiDialog-root .MuiDialogActions-root .MuiButtonBase-root {
  background: #edf1f2;
  color: #000;
}
.MuiDialog-root .MuiDialogActions-root .MuiButtonBase-root:hover {
  background: #e4eaec;
}
.MuiDialog-root .MuiDialogActions-root .MuiButtonBase-root:last-child {
  background: #007191;
  color: #fff;
}
.MuiDialog-root .MuiDialogActions-root .MuiButtonBase-root:last-child:hover {
  background: #006582;
}

.grid {
  display: grid;
  gap: 2rem;
}
.grid.grid-2 {
  grid-template-columns: 1fr 1fr;
}
.grid.grid-3 {
  grid-template-columns: 1fr 1fr 1fr;
}
.grid.subgrid {
  grid-template-rows: auto 1fr;
}
.grid nav {
  margin-top: 1.5rem;
}

.border-top {
  border-top: 7px solid #b4b4b4;
}

.border-top-orange {
  border-top: 7px solid #f07b00;
}

.border-top-petrol {
  border-top: 7px solid #007191;
}

.border-top-grasgruen {
  border-top: 7px solid #01a38a;
}

.border-top-hellblau {
  border-top: 7px solid #22b9d6;
}

.border-top-aubergine {
  border-top: 7px solid #8d1d46;
}

.border-top-rot {
  border-top: 7px solid #e42112;
}

.border-top-gelb {
  border-top: 7px solid #ffca01;
}

.border-top-gruen {
  border-top: 7px solid #27ae60;
}

.border-top-violett {
  border-top: 7px solid #9b59b6;
}

.news-header {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}
.news-header .news-logo {
  width: 75px;
}
.news-header .news-title {
  background: #007191;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.app-list h1,
.app-list h2 {
  font-size: 1.375rem;
  text-transform: uppercase;
  letter-spacing: 0.075em;
}
.app-list .AppLink {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 1.25rem 0;
  transition: all 0.25s ease;
  border-radius: 10px;
}
.app-list .AppLink .AppTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.3rem;
  margin-right: auto;
}
.app-list .AppLink .AppTitle .AppTitleFriendly {
  display: flex;
  gap: 0.5rem;
}
.app-list .AppLink .AppTitle .lead {
  font-family: museo_sns_w00_cond_500regular;
  font-size: 1.375rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.app-list .AppLink .AppTitle .MuiChip-root {
  background: #27ae60;
  color: #fff;
  font-family: museo_sns_w00_cond_500regular;
}
.app-list .AppLink .AppTitle .MuiChip-root svg {
  color: #fff;
  height: 0.75rem;
}
.app-list .AppLink.external .AppTitle svg {
  height: 0.8rem;
  color: #b4b4b4;
}
.app-list .AppLink:hover {
  text-decoration: none;
  background: #edf1f2;
}
.app-list .AppLink:hover .AppDetailsLink svg {
  opacity: 0.7;
}
.app-list .AppLink .AppDetailsLink {
  padding-right: 1rem;
}
.app-list .AppLink .AppDetailsLink svg {
  opacity: 0;
  font-size: 1.2rem;
  transition: opacity 0.25s ease;
}
.app-list .AppLink .AppDetailsLink svg:hover {
  opacity: 0.9;
}

.list-unstyled {
  list-style-type: none;
  padding: 0;
}

.icon {
  display: grid;
  place-items: center;
  color: #fff;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 10px;
}
.icon svg {
  font-size: 2rem;
}
.icon.info {
  background: #22b9d6;
}
.icon.warning {
  background: #ffdf67;
  color: #444;
}
.icon.critical {
  background: #f37369;
}
.icon.orange {
  background: #f07b00;
}
.icon.grasgruen {
  background: #01a38a;
}
.icon.hellblau {
  background: #22b9d6;
}
.icon.aubergine {
  background: #8d1d46;
}
.icon.grau {
  background: #b4b4b4;
}
.icon.violett {
  background: #9b59b6;
}

.loadingSpinner {
  font-size: 2rem;
  --fa-primary-color: #007191;
  --fa-secondary-color: #f07b00;
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
  animation: loading 1s ease-in-out infinite;
}

@keyframes loading {
  from {
    transform: rotate(40deg);
  }
  to {
    transform: rotate(400deg);
  }
}
.crArchive {
  border: none;
  padding-bottom: 1rem;
  overflow-y: auto;
}
@media (max-width: 767px) {
  .crArchive {
    max-height: 177px;
  }
}
.crArchive div:nth-child(n+4) {
  display: none;
}
.crArchive .crArchiveItem {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem 2px;
  font-family: museo_sns_w00_cond_500regular;
  letter-spacing: 0.02em;
  font-size: 1.25rem;
  transition: all 0.25s ease;
  border-radius: 10px;
}
@media (min-width: 992px) {
  .crArchive .crArchiveItem {
    font-size: 1.25rem;
  }
}
.crArchive .crArchiveItem .crArchiveItemDate {
  color: #007191;
  font-family: museo_slab_w00_300regular;
  font-size: 1rem;
  min-width: 70px;
  padding-left: 0.2rem;
  transition: all 0.25s ease;
}
.crArchive .crArchiveItem:hover {
  text-decoration: none;
  background: #edf1f2;
}
.crArchive .crArchiveItem .crArchiveItemImage,
.crArchive .crArchiveItem .crArchiveItemDescription {
  display: none;
}

.icon {
  min-width: 58px;
}

@media screen and (min-width: 1536px) {
  .MuiContainer-root {
    max-width: 1536px;
  }
}
@media screen and (max-width: 1536px) {
  .MuiContainer-root {
    max-width: 1200px;
  }
}
@media screen and (max-width: 1100px) {
  .grid.grid-3 {
    grid-template-columns: 1fr 1fr;
  }
  .details .grid {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 768px) {
  header .MuiButton-root {
    display: none;
  }
  .grid.grid-2, .grid.grid-3 {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 600px) {
  .MuiPaper-root {
    padding: 1rem;
  }
  header .MuiContainer-root {
    padding: 0;
  }
  .WarnMessage {
    flex-direction: column;
    text-align: center;
  }
}
@media (any-hover: none) {
  .app-list .AppLink .AppDetailsLink {
    padding-right: 0;
  }
  .app-list .AppLink .AppDetailsLink svg {
    opacity: 0.4;
  }
}